<template>
  <div
    class="p-5 rounded-3 shadow-sm config-display bg-white m-sm-4"
    :style="
      device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
    "
  >
    <!-- <label
      for=""
      class="fnt-medium fnt-subtitle mb-3"
      :class="device == 'mobile' ? 'fnt-body' : ''"
      >{{ userGroupInfo }}</label
    > -->
    <label
      for=""
      class="fnt-medium fnt-subtitle mb-3"
      :class="device == 'mobile' ? 'fnt-body' : ''"
      >{{
        $t("517") +
        " : " +
        userGroupInfo.strUserFieldNameTH +
        " (" +
        userGroupInfo.strUserGroupNameEN +
        ")"
      }}</label
    >
    <div class="row g-3">
      <div class="col-md-6 col-sm-12">
        <div class="d-flex justify-content-between align-items-center p-3">
          <label
            for=""
            class="fnt-medium fnt-subtitle p-0 m-0"
            :class="device == 'mobile' ? 'fnt-caption' : ''"
            >{{ $t("4") }}</label
          >
        </div>
        <div class="d-flex justify-content-between align-items-center p-3">
          <Search
            :mode="'auto'"
            :hint="$t('447', { fieldname: $t('528').toLowerCase() })"
            @onSearch="searchUserFieldList($event)"
            @onClean="clear()"
            ref="Search"
          />
        </div>
        <div class="left-config-display rounded-2 p-3 mx-3">
          <div
            class="config-list-group"
            :style="device == 'mobile' ? 'height:150px !important;' : ''"
          >
            <div
              class="
                p-3
                rounded-2
                d-flex
                justify-content-between
                align-items-center
                w-100
              "
            >
              <label
                for=""
                class="fnt-medium fnt-subtitle p-0 m-0"
                :class="device == 'mobile' ? 'fnt-caption' : ''"
                >{{ $t("4") }}</label
              >
            </div>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                w-100
              "
            >
              <template v-if="UserFieldListDummy.length != 0">
                <div
                  v-for="(item, index) in UserFieldListDummy"
                  :key="index"
                  class="w-100"
                >
                  <ListAdd
                    @Add="addSelectList(item)"
                    :name="
                      typeof item == 'string'
                        ? $t(item)
                        : item[filterLang(item)]
                    "
                  />
                </div>
              </template>
              <!-- <draggable v-model="UserFieldList" transition="100" class="drop-zone">
                <template v-slot:item="{ item }">
                  <div>
                    <ListAdd @Add="selectionList(item)" :name="$t(item.head)" />
                  </div>
                </template>
                 </draggable>
              -->

              <template v-if="UserFieldList.length == 0">
                <label for="" class="p-3">{{ $t("81") }}</label>
              </template>
            </div>
            <!-- <pre>{{ JSON.stringify(UserFieldList, undefined, 4) }}</pre> -->
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="d-flex justify-content-between align-items-center p-3">
          <label
            for=""
            class="fnt-medium fnt-subtitle p-0 m-0"
            :class="device == 'mobile' ? 'fnt-caption' : ''"
            >{{ $t("529") }}</label
          >
        </div>
        <div class="d-flex justify-content-between align-items-center p-3">
          <Search
            :mode="'auto'"
            :hint="$t('447', { fieldname: $t('528').toLowerCase() })"
            @onSearch="searchUserFieldLink($event)"
            @onClean="clear()"
            ref="Search"
          />
        </div>
        <div class="left-config-display rounded-2 p-3 mx-3">
          <div
            class="config-list-group"
            v-if="SelectedListOld != []"
            :style="device == 'mobile' ? 'height:150px !important;' : ''"
          >
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                w-100
              "
            >
              <!-- list-group-item -->
              <div
                class="
                  p-3
                  rounded-2
                  d-flex
                  justify-content-between
                  align-items-center
                  w-100
                "
              >
                <div class="d-flex align-items-center">
                  <label
                    for=""
                    class="fnt-medium fnt-subtitle p-0 m-0"
                    :class="device == 'mobile' ? 'fnt-caption' : ''"
                    >{{ $t("4") }}</label
                  >
                </div>
                <div class="d-flex align-items-center me-2">
                  <label
                    for=""
                    class="fnt-medium fnt-subtitle text-center p-0 m-0"
                    :class="device == 'mobile' ? 'fnt-caption' : ''"
                    :style="device == 'mobile' ? 'width: 30px' : 'width: 50px'"
                    >{{ $t("522") }}</label
                  >
                  <label
                    for=""
                    class="fnt-medium fnt-subtitle text-center p-0 m-0"
                    :class="device == 'mobile' ? 'fnt-caption' : ''"
                    :style="device == 'mobile' ? 'width: 30px' : 'width: 50px'"
                    >{{ $t("238") }}</label
                  >
                </div>
              </div>

              <draggable
                v-if="show == true"
                v-model="UserFieldLinkDummy"
                transition="100"
                ref="draggable"
                class="drop-zone w-100"
                :groupType="''"
              >
                <template v-slot:item="{ item }">
                  <div>
                    <component
                      :is="'ListClose'"
                      @Remove="unselectionList(item)"
                      :name="item[filterLang(item)]"
                      :checkView="item.blnViewFlag == 1 ? true : false"
                      :checkEdit="item.blnEditFlag == 1 ? true : false"
                      @View="SeleteView(item, $event)"
                      @Edit="SeleteEdit(item, $event)"
                    />
                  </div>
                </template>
              </draggable>
            </div>
            <!-- <pre>{{ JSON.stringify(SelectedList, undefined, 4) }}</pre> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 mx-3 d-flex">
      <Button
        @click="onSaveSelectedList()"
        :name="$t('36')"
        class="btn-fix btn-primary me-3"
      />
      <Button
        @click="$emit('Cancel')"
        :name="$t('35')"
        class="btn-fix btn-cancel"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/button/button.vue";
import ListClose from "@/components/form/UserGroup/listRemove.vue";
import ListAdd from "@/components/form/UserGroup/listAdd.vue";
//   import ListLock from "@/components/form/UserGroup/listLock.vue";
import Search from "@/components/input/inputSearch.vue";
import customfilter from "@/typescript/filter";
import Draggable from "@/components/DraggableContainer";
import Enumerable from "linq";
export default {
  components: {
    ListClose,
    ListAdd,
    //   ListLock,
    Button,
    Search,
    Draggable,
  },
  emits: ["Cancel", "Add"],
  props: {
    UserFieldList: { type: Object },
    UserFieldLink: { type: Object },
    userGroupInfo: { type: Object },
  },
  mounted() {
    this.UserFieldLinkDummy = this.UserFieldLink;
    this.UserFieldListDummy = this.UserFieldList;
    this.UserFieldLinkClone = this.UserFieldLink;
    this.UserFieldListClone = this.UserFieldList;
    this.UserFieldListDummy = Enumerable.from(this.UserFieldListDummy)
      .orderByDescending((r) => r.intUserFieldID)
      .toArray();
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
  data() {
    return {
      show: false,
      locales: String,
      UserFieldListDummy: { type: Object },
      UserFieldLinkDummy: { type: Object },
      UserFieldListClone: { type: Object },
      UserFieldLinkClone: { type: Object },
      blnSystemword: false,
    };
  },

  methods: {
    searchUserFieldList(key) {
      if (key != null) {
        this.UserFieldListDummy = this.filterSelected(
          { ...this.UserFieldListClone },
          key
        );
      } else {
        this.UserFieldListDummy = { ...this.UserFieldListClone };
      }
    },
    searchUserFieldLink(key) {
      this.show = false;

      if (key != null) {
        this.UserFieldLinkDummy = this.filterSelected(
          { ...this.UserFieldLinkClone },
          key
        );
      } else {
        this.UserFieldLinkDummy = { ...this.UserFieldLinkClone };
      }
      setTimeout(() => {
        this.show = true;
      }, 1);
    },

    filterSelected(array, event) {
      // console.log(array);
      // const keysExact = ["NameEN", "NameTH"];
      return Object.values(array).filter((item) => {
        if (this.blnSystemword == false) {
          return (
            item[this.filterLang(item)]
              .toString()
              .toLowerCase()
              .indexOf(event.toString().toLowerCase()) !== -1
          );
        } else if (this.blnSystemword == true) {
          // console.log(this.$t(item["lang"]));
          return (
            this.$t(item["lang"])
              .toString()
              .toLowerCase()
              .indexOf(event.toString().toLowerCase()) !== -1
          );
        }
      });
    },
    onSaveSelectedList() {
      console.log(this.UserFieldLinkDummy);
      this.$emit("Add", this.UserFieldLinkDummy);
    },
    SeleteView(item, event) {
      item.blnViewFlag = event == true ? 1 : 0;
    },
    SeleteEdit(item, event) {
      item.blnEditFlag = event == true ? 1 : 0;
    },
    filterLang(items) {
      // console.log(this.defaultLang);
      if (items !== undefined && this.$i18n.locale == null) {
        return customfilter.filterLang(items, this.$i18n.locale);
      } else if (items !== undefined && this.$i18n.locale != null) {
        return customfilter.filterLang(items, this.$i18n.locale);
      }
    },

    async addSelectList(item) {
      this.show = false;
      var list = {
        intUserGroupFieldLinkID: 0,
        strUserFieldNameTH: item.strUserFieldNameTH,
        strUserFieldNameEN: item.strUserFieldNameEN,
        blnViewFlag:
          item.blnEditFlag == false
            ? item.blnViewFlag == true || item.blnViewFlag == 1
              ? 1
              : 0
            : 1,
        blnEditFlag: item.blnEditFlag == true || item.blnEditFlag == 1 ? 1 : 0,
        intUserFieldID: item.intUserFieldID,
      };

      this.UserFieldLinkDummy.push(list);

      this.UserFieldListDummy = await this.UserFieldListDummy.filter((ele) => {
        return ele.intUserFieldID != item.intUserFieldID;
      });
      this.UserFieldLinkClone = this.UserFieldLinkDummy;
      setTimeout(() => {
        this.show = true;
      }, 10);
    },
    async unselectionList(item) {
      this.show = false;
      var list = {
        strUserFieldNameTH: item.strUserFieldNameTH,
        strUserFieldNameEN: item.strUserFieldNameEN,

        blnEditFlag: item.blnEditFlag,
        intUserFieldID: item.intUserFieldID,
      };

      this.UserFieldListDummy.push(list);

      this.UserFieldLinkDummy = await this.UserFieldLinkDummy.filter((ele) => {
        return ele.intUserFieldID != item.intUserFieldID;
      });

      this.UserFieldListDummy = await Enumerable.from(this.UserFieldListDummy)
        .orderByDescending((r) => r.intUserFieldID)
        .toArray();
      this.UserFieldListClone = this.UserFieldListDummy;
      setTimeout(() => {
        this.show = true;
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.config-display {
  z-index: 2;
  width: 70%;
  .left-config-display {
    border: solid 1px $black-300;
    .config-list-group {
      height: 350px;
      overflow: auto;
    }
  }
  .right-config-display {
    border: solid 1px $black-300;
    .config-list-group {
      height: 350px;
      overflow: auto;
    }
  }
}
.draggable-item {
  display: flex;

  margin: 1%;
  padding: 1%;
}
.drop-zone {
  margin: 0px;
  padding: 0px;
}
pre {
  background-color: #eee;
  margin: 30px;
  padding: 10px;
  width: 400px;
  min-height: 200px;
}
.btn-sm {
  width: 66px !important;
}
.list-mobile {
  padding: 4px !important;
}
</style>
