<template>
  <div
    class="
      list-group-item
      mb-2
      rounded-2
      border
      d-flex
      justify-content-between
      align-items-center
      bg-black-100
      p-sm-2
    "
    :class="device == 'mobile' ? 'list-mobile' : ''"
    :style="device == 'mobile' ? 'margin-bottom:4px !important;' : ''"
  >
    <div class="d-flex align-items-center">
      <button class="btn p-0 m-0" @click="$emit('Remove')">
        <moveLeft width="18" height="18" class="fill-secondary me-2" />
      </button>
      <div
        class="d-flex align-items-center fnt-regular fnt-subtitle"
        :style="device == 'mobile' ? 'font-size:10px !important;' : ''"
      >
        <label for="">{{ name }}</label>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div
        :style="device == 'mobile' ? 'width: 30px' : 'width: 50px'"
        class="d-flex justify-content-center"
      >
        <input
          type="checkbox"
          :class="device == 'mobile' ? 'form-check-input' : 'form-check-input'"
          @change="selectedView()"
          :value="true"
          v-model="checkDrdView"
          :checked="checkView"
        />
      </div>
      <div
        :style="device == 'mobile' ? 'width: 30px' : 'width: 50px'"
        class="d-flex justify-content-center"
      >
        <input
          type="checkbox"
          class="form-check-input"
          @change="selectedEdit()"
          :value="true"
          v-model="checkDrdEdit"
          :checked="checkEdit"
        />
      </div>
      <button class="btn p-0 m-0">
        <Drag width="18" height="18" class="fill-secondary" />
      </button>
    </div>
  </div>
</template>

<script>
import { moveLeft, Drag } from "@/components/icon/index";
export default {
  components: {
    moveLeft,
    Drag,
  },
  emits: ["Remove", "View", "Edit"],
  props: {
    name: { type: String },
    checkView: { type: Boolean },
    checkEdit: { type: Boolean },
  },
  data() {
    return {
      checkDrdView: null,
      checkDrdEdit: null,
    };
  },

  mounted() {
    this.checkDrdView = this.checkView;
    this.checkDrdEdit = this.checkEdit;
  },
  methods: {
    selectedView() {
      if (this.checkDrdView == false) {
        this.checkDrdEdit = false;
      }
      this.$emit("View", this.checkDrdView);
    },
    selectedEdit() {
      this.$emit("Edit", this.checkDrdEdit);
    },
  },
};
</script>

<style lang="scss" scoped>
.hover {
  &:hover {
    color: $secondary-600 !important;
  }
}
</style>