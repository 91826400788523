<template>
  <div
    class="popup-container align-items-center"
    v-show="blnAltHavedata == true"
  >
    <transition name="zoom">
      <AlertPopup
        v-show="blnAltHavedata == true"
        :altMsgBox="$t('216')"
        class="warning"
        classOkBtn="btn-warning text-white"
        classCancelBtn="text-warning"
        artIcon="WarningOutline"
        @confirm="confirmAltPopup($event)"
        classIcon="text-secondary fill-secondary"
        :strButtonSubmit="'218'"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnAltHavedata == true"
        @click="blnAltHavedata = false"
      ></div>
    </transition>
  </div>
  <div
    class="border-radius-lg form"
    :class="device == 'mobile' ? 'p-3' : 'pt-6 pb-4 px-5'"
  >
    <div class="row m-0 gx-3 p-0">
      <div class="d-flex justify-content-between w-100 mb-4 p-0">
        <HeadLabel :label="$t('517')" class="fnt-medium fnt-subtitle" />
        <HeadLabel label="34" class="text-danger-500 fnt-regular fnt-caption" />
      </div>
      <div class="col-lg-12 mb-3 pe-lg-2 ps-sm-0 col-sm-12 p-0">
        <InputText
          :label="$t('519')"
          :value="new_data['strUserGroupNameTH']"
          @input="updateData('strUserGroupNameTH', $event, 'InputText')"
          ref="strUserGroupNameTH"
          validate="letterTH"
          alertMsg="202"
        />
      </div>
      <div class="col-lg-12 mb-3 pe-lg-2 ps-sm-0 col-sm-12 p-0">
        <InputText
          :label="$t('518')"
          @input="updateData('strUserGroupNameEN', $event, 'InputText')"
          :value="new_data['strUserGroupNameEN']"
          ref="strUserGroupNameEN"
          validate="letterEN"
          alertMsg="202"
        />
      </div>
      <div class="mb-lg-3 mb-sm-2 p-0">
        <Toggle
          open="214"
          close="215"
          alertMsg="524"
          altMsgBox="524"
          tooltipMsg="217"
          :name="'521'"
          :value="new_data['blnUserGroupStatus']"
          @input="updateData('blnUserGroupStatus', $event, 'Toggle')"
          ref="blnUserGroupStatus"
        />
      </div>
    </div>
    <div class="d-flex justify-content-between mt-4">
      <div class="d-flex">
        <div class="modal-footer border-0 justify-content-start p-0">
          <Button
            class="btn-fix btn-primary me-3"
            :name="'36'"
            @input="addData()"
          />
          <Button
            class="btn-fix btn-cancel"
            :name="'35'"
            @input="closeForm()"
          />
        </div>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center">
      <DeleteinForm
        class="mt-3"
        @input="deleteData()"
        classBtnIcon="fill-secondary"
        icon="DeleteFill"
        ref="blnDeleteinForm"
      />
    </div>
  </div>
</template>

<script>
import InputText from "../input/inputText.vue";
import Toggle from "../input/toggle/toggleForm.vue";
import Button from "../../components/button/button.vue";
import HeadLabel from "../../components/label/label.vue";
import DeleteinForm from "@/components/button/deleteinform.vue";
import AlertPopup from "@/components/alert/alertPopup.vue";
export default {
  components: {
    DeleteinForm,
    InputText,
    Toggle,
    Button,
    HeadLabel,
    AlertPopup,
  },
  emits: ["onInput", "Insert", "Update", "delete"],

  data() {
    return {
      overlay: true,
      new_data: {
        strUserGroupNameTH: "",
        strUserGroupNameEN: "",
        blnUserGroupStatus: 0,
      },
      blnAltHavedata: false,
      request: ["strUserGroupNameTH", "strUserGroupNameEN"],
    };
  },

  mounted() {
    if (this.inactive !== undefined && this.inactive !== null) {
      this.componentsInactive(this.inactive);
    }

    this.$refs.blnUserGroupStatus.tooltip = true;
  },
  created() {
    this.new_data = { ...this.data };
  },
  props: {
    data: {
      type: Object,
    },
    inactive: {
      type: Array,
    },
    action: { type: String },
    msgalertDataDuplicate: { type: Object },
  },

  methods: {
    async addData() {
      if (this.action == "Insert") {
        if ((await this.detectDatainField()) == true) {
          this.$emit("Insert", this.new_data);
        }
      } else if (this.action == "Update") {
        if ((await this.detectDatainField()) == true) {
          this.$emit("Update", this.new_data);
        }
      }
    },
    deleteData() {
      console.log("click button delete");
      this.$emit("delete");
    },
    confirmAltPopup(state) {
      console.log(state);
      if (state == false) {
        this.blnAltHavedata = false;
      } else if (state == true) {
        this.blnAltHavedata = false;
        this.$emit("onInput", false);
        console.log(state);
      }
    },
    componentsInactive(inactive) {
      if (Array.isArray(inactive)) {
        inactive.forEach((element) => {
          console.log(element.name);
          console.log(this.$refs[element.name]);
          if (element.inactive !== undefined) {
            console.log(this.$refs[element.name]);
            this.$refs[element.name].inactive = element.inactive;
          } else if (element.tooltip !== undefined) {
            this.$refs[element.name].tooltip = element.tooltip;
          } else if (element.blnConfirm !== undefined) {
            this.$refs[element.name].blnConfirm = element.blnConfirm;
          } else if (element.strResult !== undefined) {
            this.$refs[element.name].strResult = element.strResult;
          } else if (element.enable !== undefined) {
            this.$refs[element.name].enable = element.enable;
          } else if (element.enablelist !== undefined) {
            this.$refs[element.name].enablelist = element.enablelist;
          } else if (element.showSub !== undefined) {
            this.$refs[element.name].showSub = element.showSub;
          }
        });
      }
      return true;
    },
    detectalertDataDuplicate(msgalertDataDuplicate) {
  
      if (Array.isArray(msgalertDataDuplicate)) {
        for (var item in msgalertDataDuplicate) {
          this.alertDataDuplicate(
            msgalertDataDuplicate[item].name,
            msgalertDataDuplicate[item].msg
           
          );

        }
      }
    },
    validate(name) {
      console.log(name);
      if (this.$refs[name] !== undefined) {
        // console.log(name);
        console.log(this.$refs[name]);
        try {
          this.$refs[name].Valid();
          console.log(this.$refs[name].alertMsgStatus);
          return this.$refs[name].alertMsgStatus;
        } catch (error) {
          console.log(error);
          return false;
        }
      }
    },
    async alertRequest(name) {
      if (this.$refs[name] !== undefined) {
        let mymethod = this.$refs[name];

        if (mymethod != null) {
          setTimeout(() => {
            // console.log(name);
            //  console.log(this.$refs[name][0]);
            this.$refs[name].alertRequest();
          }, 200);
        }
      }
    },

    alertDataDuplicate(name, msg) {

      this.$refs[name].alertDataDuplicate(msg);
  
    },
    async detectDatainField() {
      var count = 0;

      for (var item in this.request) {
        console.log(this.new_data[this.request[item]].toString() == "");
        console.log(this.new_data);
        // console.log(this.$refs.DynamicForm.validate(this.request[item]));
        var validate = this.validate(this.request[item]);
        console.log(this.validate(this.request[item]));
        if (
          validate == true ||
          this.new_data[this.request[item]] === undefined ||
          this.new_data[this.request[item]] === null
        ) {
          await this.alertRequest(this.request[item]);
          count += 1;
        } else if (this.new_data[this.request[item]] !== undefined) {
          if (this.new_data[this.request[item]].toString() == "") {
            await this.alertRequest(this.request[item]);
            count += 1;
          }
        } else if (validate == true) {
          await this.alertRequest(this.request[item]);
          count += 1;
        }
      }
      console.log("Count  :" + count);
      if (count > 0) {
        return false;
      } else if (count == 0) {
        return true;
      }
    },

    async closeForm() {
      this.blnAltHavedata = false;
      let isEqual = await (JSON.stringify(this.new_data) ===
        JSON.stringify(this.data));
      console.log(isEqual);
      console.log(this.new_data != null);
      console.log("clsoeform");
      if (this.new_data != null && isEqual == false) {
        for (var item in this.new_data) {
          if (this.new_data[item] !== undefined) {
            this.overlay = true;
            this.blnAltHavedata = true;
          }
        }
      }
      if (this.blnAltHavedata == false || isEqual == true) {
        this.$emit("onInput", false);
      }
    },
    AddData({ state, value }) {
      this.new_data[state] = value;
    },
    updateData(state, event, type) {
      // ถ้าเป็นcheckbox จะดึงจะจาก checked
      console.log(event);
      if (type === "Toggle") {
        console.log(state);
        console.log(event.value);
        if (event.value == true) {
          this.AddData({ state, value: 1 });

          state = "confirm" + state;
          this.AddData({ state, value: event.confirm });
        }
        if (event.value == false) {
          this.AddData({ state, value: 0 });
          state = "confirm" + state;
          this.AddData({ state, value: event.confirm });
        }
      }
      // ถ้าเป็นinputtext ปกติ จะดึงจะจาก value
      else if (type != "Toggle") {
        this.AddData({ state, value: event.target.value });
      }
    },
  },
};
</script>

<style>
</style>